import { StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const ratingMixin: Style = {
	border: 2,
	borderRadius: 5,
	padding: '8 16 16 16',
	width: '100%',
	display: 'flex',
	flexDirection: 'row',
	gap: 15,
	borderColor: '#fff'
};

//  A4 Page size: [595.28, 841.89]
export const styles = StyleSheet.create({
	// * Main
	page: {
		fontSize: 10,
		fontFamily: 'Poppins, sans-serif',
	},
	background: {
		position: 'absolute',
		left: 0,
		top: 0,
		zIndex: '-1',
		width: '100%',
		height: '100%'
	},
	container: {
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		fontSize: 18
	},
	content: {
		flexDirection: 'column',
		padding: '50',
		color: '#fff'
	},

	// * Logo
	logo: {
		display: 'flex',
		alignItems: 'flex-end',
		marginBottom: 32,
		width: '100%',
	},

	// * Title
	title: {
		fontSize: '32',
		fontWeight: 'bold',
		marginBottom: 32,
		lineHeight: 1.2
	},

	// * Info
	info: {
		marginBottom: 32,
	},

	// * Parameters
	parameters: {
		marginBottom: 32
	},
	highlight: {
		fontWeight: 'bold'
	},
	parameter: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: 8,
		fontWeight: 'bold',
		fontSize: 32
	},

	// * Ratings
	ratings: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		gap: 46,
		marginBottom: 32
	},
	ratingLight: {
		...ratingMixin,
		backgroundColor: '#fff',
		color: '#02356C'
	},
	ratingDark: {
		...ratingMixin,
		backgroundColor: '#02356C',
		color: '#fff'
	},
	ratingValue: {
		fontSize: 32,
		fontWeight: 'bold'
	},

	// * Description
	description: {
		fontSize: 9,
		marginBottom: 32
	},

	// * Copyright
	copyright: {
		fontSize: 9,
		color: '#a6a6a6'
	},
});
