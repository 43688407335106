import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import s from './Modal.module.scss';
import cn from 'classnames';
import { ReactComponent as CloseSVG } from 'src/shared/assets/svg/close.svg';
import { Portal } from '../../Portal/Portal';

const ANIMATION_DELAY = 300;

interface Props {
	className?: string;
	children?: ReactNode;
	isOpen?: boolean;
	onClose?: () => void;
	maxWidth?: string;
}

export const Modal: React.FC<Props> = props => {
	const {
		className,
		children,
		isOpen,
		onClose,
		maxWidth = '90%'
	} = props;

	const [isClosing, setIsClosing] = useState(false);
	const timeRef = useRef<ReturnType<typeof setTimeout>>();

	const closeHandler = useCallback((event?: React.MouseEvent) => {
		event?.stopPropagation();

		if (onClose) {
			setIsClosing(true);
			timeRef.current = setTimeout(() => {
				onClose();
				setIsClosing(false);
			}, ANIMATION_DELAY);
		}
	}, [onClose],);

	const onKeyDown = useCallback((event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			closeHandler();
		}
	}, [closeHandler]);

	const onContentClick = (event: React.MouseEvent) => {
		event.stopPropagation();
	};

	useEffect(() => {
		if (isOpen) {
			window.addEventListener('keydown', onKeyDown);
		}

		return () => {
			clearTimeout(timeRef.current);
			window.removeEventListener('keydown', onKeyDown);
		};
	}, [isOpen, onKeyDown]);

	const classNames = cn(
		s.container,
		isOpen && s.opened,
		isClosing && s.isClosing,
		className
	);

	// * Render
	return (
		<Portal element={document.getElementById('modal-portal') as HTMLElement}>
			<div className={classNames}>
				<div
					className={s.overlay}
					onClick={closeHandler}
				>
					<div
						className={s.content}
						style={{maxWidth}}
						onClick={onContentClick}
					>
						<div className={s.content_inner}>
							{children}
						</div>

						{onClose && (
							<div
								className={s.close}
								onClick={onClose}
							>
								<CloseSVG/>
							</div>
						)}
					</div>
				</div>
			</div>
		</Portal>
	);
};
