import poppins from '../../app/fonts/Poppins-Regular.ttf';
import poppinsBold from '../../app/fonts/Poppins-Bold.ttf';
import poppinsLight from '../../app/fonts/Poppins-Light.ttf';

import { ReactElement } from 'react';
import { AppRouter } from './router/AppRouter';
import { theme } from 'src/app/styles/theme';
import { Provider } from 'react-redux';
import { store } from 'src/app/BLL/store';
import { ThemeProvider } from '@mui/material';
import { Font } from '@react-pdf/renderer';
import { NotificationController } from 'src/pages/app/components/notifications/NotificationController';

export const appDecorator = (Component: ReactElement<any, any>) => (
	<ThemeProvider theme={theme}>
		<Provider store={store}>
			{Component}
		</Provider>
	</ThemeProvider>
);

export const App = () => {

	// * Font register for PDF renderer.
	Font.register({
		family: 'Poppins, sans-serif',
		fonts: [
			{
				src: poppins
			},
			{
				src: poppinsBold,
				fontWeight: 'bold'
			},
			{
				src: poppinsLight,
				fontWeight: 'light'
			}
		]
	});

	// * Component
	return (<>
		{appDecorator(<>
			<NotificationController/>
			<AppRouter/>
		</>)}
	</>);
};
