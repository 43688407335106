import React from 'react';
import s from './ValueTooltip.module.scss';
import { Tooltip } from '@mui/material';
import { Icon } from 'src/shared/ui/Icon';
import { ReactComponent as DotsSVG } from 'src/shared/assets/svg/dots_in_circle.svg';
import { nullToString } from 'src/pages/company/ui/dataPointGenerator/fields/_helpers/valueHelpers';

interface Props {
	title: string;
}

export const ValueTooltip: React.FC<Props> = props => {
	const { title } = props;

	// * Render
	return (
		<Tooltip
			title={nullToString(title)}
			placement="right-start"
		>
			<div className={s.inner}>
				<Icon svg={<DotsSVG />} />
			</div>
		</Tooltip>
	);
};
