import React, { ComponentProps } from 'react';
import s from './ServicesList.module.scss';
import globalSVG from 'src/shared/assets/svg/illustrations/undraw_around_the_world_re_rb1p.svg';
import homeSVG from 'src/shared/assets/svg/illustrations/undraw_sweet_home_dkhr.svg';
import goldSvg from 'src/shared/assets/svg/illustrations/undraw_data_trends_re_2cdy.svg';
import { ServiceCard } from 'src/entities/ServiceCard';
import { DenominatorSubLogo } from 'src/shared/ui/DenominatorSubLogo';
import { Heading } from 'src/shared/ui/Heading';

export const ServicesList: React.FC = () => {

	const serviceCards: ComponentProps<typeof ServiceCard>[] = [
		{
			Title: <DenominatorSubLogo sub="Global"/>,
			Description: 'DEI data and ratings on companies across more than 190 countries and 85+ industries. Global comparable data & ratings on Diversity, Equity, & Inclusion performance for both public and private companies. The ratings are the most comprehensive on the market, include forecasting capabilities and are built on multiple international frameworks such as UN Global Compact’s Sustainable Development Goals and World Economic Forum’s Gender Parity.',
			img: globalSVG,
			button: {
				text: 'to Denominator Global',
				onClick: () => window.open('https://global.denominator.com', '_blank')
			}
		},
		{
			Title: <DenominatorSubLogo sub="Country"/>,
			Description: <><DenominatorSubLogo
				sub="Country"/> {'provides a holistic assessment of countries\' DEI performance. The index empowers policymakers, organizations, and other stakeholders to identify areas of strength and weakness and develop strategies to drive positive change. By providing this data, Denominator brings transparency to DEI globally and foster a more diverse, equitable, and inclusive world for everyone.'}</>,
			img: goldSvg,
			button: {
				text: 'to Denominator Country',
				onClick: () => window.open('https://country.denominator.com', '_blank')
			}
		},
		{
			Title: 'Home page',
			Description: <>Visit Denominator website to learn more about the offering (data & ratings) and Denominator
				solutions, insights, and methodology.
				If any enquiry, contact us at <a href="mailto:info@denominator.com">info@denominator.com</a></>,
			img: homeSVG,
			button: {
				text: 'to Home Page',
				onClick: () => window.open('https://www.denominator.com', '_blank')
			}
		}
	];

	// * Render
	return (
		<div>
			<Heading level={2} marginBottom="m">
				Other Denominator services
			</Heading>

			<div className={s.cardsList}>
				{serviceCards.map((service, index) => (
					<ServiceCard
						data-test={'service_card_' + index.toString()}
						key={index}
						{...service}
					/>
				))}
			</div>
		</div>
	);
};
